<template>
  <div>
    <nav class="navbar navbar-expand-md navbar-dark bg-dark">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">Monitor Nfe/Nfce</a>
        <div class="d-flex" action="#">
          <input class="form-control me-2" v-model="search" v-mask="'##.###.###/####-##'" type="search" placeholder="Pesquise por CNPJ" aria-label="Search">
          <button class="btn btn-outline-success" type="submit">Buscar</button>
        </div>
    </div>  
    </nav>

    <main class="container mt-5">
      <div class="row">
        <div class="col-8 mb-3">
          <h5>Esses são os últimos 10/50 registros do serviço NF-e/NFC-e</h5>
          <small>Reload a cada 5 minutos se não houver pesquisa sendo realizada.</small>
        </div>
        <div class="col-4">
            <!-- <div class="row">
              <div class="col-6">
                <button type="button" class="btn btn-success">Aprovadas</button>
              </div>
              <div class="col-6">
                <button type="button" class="btn btn-warning">Não aprovadas</button>
              </div>
            </div> -->
        </div>
      </div>
      <div class="row">
        <div class="col-12 ">

        <div class="wrap-table100">
				<div class="table100 ver1">
					<div class=" ">
						<div class="table100-nextcols">
							<table>
								<thead>
									<tr class="row100 head">
										<th class="cell100 column2">Id</th>
										<th class="cell100 column3">Status</th>
										<th class="cell100 column4">Mensagem</th>
										<th class="cell100 column5">CNPJ/CPF</th>
										<th class="cell100 column6">NÚMERO</th>
										<th class="cell100 column7">Chave Nf-e/NFC-e</th>
										<th class="cell100 column8">Data</th>
									</tr>
								</thead>
								<tbody >
                  <tr class="row100 body" v-if="pages[pageSelect].length == 0">
                    <td colspan="5">
                      <center>
                        Sem resultados
                      </center>
                    </td>
                  </tr>
									<tr class="row100 body" v-for="nfe in pages[pageSelect]" :key="nfe.nfeId">
										<td class="cell100 column2">{{nfe.nfceId}}</td>
										<td class="cell100 column3">
                      <span class="badge bg-success" v-if="nfe.status ==100">{{nfe.status}}</span>
                      <!-- <span class="badge bg-warning text-dark" v-if="nfe.status !=100">{{nfe.status}}</span> -->
                      
                    </td>
										<td class="cell100 column4">
                      <div class="text-warning"  v-if="nfe.status !=100">
                        <span class="cursor" v-if="nfe.message.length > 31" :title="nfe.message" >
                          {{nfe.message.substring(0,31)}} ...
                          <span class="badge bg-warning" @click="openModal(nfe)" ><i class="fas fa-eye"></i> Ver mais </span>
                        </span>
                        <span v-if="nfe.message.length <= 31">
                        {{nfe.message}}
                        </span>
                      </div>
                      <div class="text-success" v-if="nfe.status ==100">
                        {{nfe.message}}
                      </div>
                    </td>
										<td class="cell100 column5">{{nfe.cnpjCpf | VMask('##.###.###/####-##') }}</td>
                    <td class="cell100 column6 text-center">{{ parseInt( nfe.chaveNfe.substring(25,34))}}</td>
										<td class="cell100 column7">
                        {{nfe.chaveNfe}}
                    </td>
										<td class="cell100 column8 ">
                     <span class="badge bg-secondary"> {{nfe.dtprocesso | moment("from", "now", true)}} </span> <br>
                      <small>{{nfe.dtprocesso | moment('DD/MM/YYYY HH:mm:ss')}}</small> 
                    </td>
									</tr>

								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

        </div>
      </div>
      <div class="row" v-if="!blockRefresh">
        <div class="col-3 offset-9 mt-3 mb-3 right">
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item"><a class="page-link" href="#" v-show="pageSelect != 0" @click=" pageSelect--">Anterior</a></li>
              <li class="page-item" v-for="(page,index) in pages" :key="index"><a class="page-link" href="#" @click="selectPage(index)" :class="{'actived' :index == pageSelect}" >{{index + 1}}</a></li>
              <li class="page-item"><a class="page-link" href="#" v-show="pageSelect != pages.length - 1" @click="pageSelect++">Próximo</a></li>
            </ul>
          </nav>
        </div>
      </div>
    </main>

    <b-modal id="modal-1" title="Detalhes" v-model="modal" hide-footer size="xl">

      <table class="info">
        <tr>
          <td>Mensagem:</td>
          <td>{{nfeSelect.message}}</td>
        </tr>
        <tr>
          <td>Status:</td>
          <td><span class="badge bg-warning text-dark" v-if="nfeSelect.status !=100">{{nfeSelect.status}}</span></td>
        </tr>
        <tr>
        <tr>
          <td>CNPJ/CPF:</td>
          <td>{{nfeSelect.cnpjCpf | VMask('##.###.###/####-##') }}</td>
        </tr>
          <tr>
          <td>Numero NF-e/NFC-e </td>
          <td v-if="nfeSelect.chaveNfe != undefined">{{ parseInt( nfeSelect.chaveNfe.substring(25,34))}}</td>
        </tr>
        <tr>
          <td>CHAVE NF-e/NFC-e </td>
          <td>{{nfeSelect.chaveNfe}}</td>
        </tr>
        <tr>
          <td>Data</td>
          <td>
            <span class="cell100 column" v-if="nfeSelect.dtprocesso != undefined">
            <span class="badge bg-secondary"> {{nfeSelect.dtprocesso | moment("from", "now", true)}}  </span> <br>
            <small>{{nfeSelect.dtprocesso | moment('DD/MM/YYYY HH:mm:ss')}}</small> 
          </span>
          </td>
        </tr>

      </table>
<!-- 
      <div class="row">
        <div class="col-6 text-center p-4">
            <button class="btn btn-primary btn-block">PDF</button>
        </div>
        <div class="col-6 text-center p-4">
            <button class="btn btn-primary btn-block">XML</button>
        </div>
      </div> -->
      
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
    data (){
    return {
      nfces: [],
      pages:[[]],
      pageSelect:0,
      search:"",
      blockRefresh:false,
      modal:false,
      nfeSelect:{}
    }
  },
  beforeMount(){
    this.getNfe();
    setInterval(() =>{ 
      if(this.blockRefresh){
        return;
      }
      console.log('Loading...')
      this.getNfe();
    },300000)
  },
  watch: {
    search: function (val){
      this.filter(val);
    }
  },
  methods:{
    openModal(nfe){
      this.nfeSelect = nfe;
      this.modal = true;
    },
    filter(cnpj){
      cnpj = cnpj.replace(/[^a-zA-Z0-9]/g,"");
      if(cnpj.length != 0){
          this.pages[0] = this.nfces.filter((value) => { return value.cnpjCpf.indexOf(cnpj) != -1 });
          this.blockRefresh = true;
      }
      else{
        this.blockRefresh = false;
        this.getNfe();
      }
    },
    selectPage(index){
      this.pageSelect = index;
    },
    valid(nfce){
      if(nfce.status == 999){
        return true;
      }

      if(nfce.status == 99){
        return true;
      }
      
      if(nfce.status == 100 && nfce.message.trim() == "" && nfce.chaveNfe == "" ){
        return true;
      } 
      else{
        return false;
      }
    },
    getNfe(){
      this.pages = [[]];
      fetch('http://api.serverlondrisoft.com:4110/Monitor')
      .then(response => response.json())
      .then(data => {
        data = data.filter(response => {  return response.status == 200 || (response.status == 100 && response.message != "")});
         this.nfces = data;
         let pageIndex = 0;
         for (let index = 0; index < this.nfces.length; index++) {
           if(this.pages[pageIndex] == undefined) {
              this.pages[pageIndex] = [];
           } 

           if(this.pages[pageIndex].length == 9){
             pageIndex++;
           }else{
             if(index != 0 && this.nfces[index].status != 99)
              this.pages[pageIndex].push(this.nfces[index]);
           }
         }
      });
    }
  }
}
</script>
<style scoped>
.info tr td{
  padding: 10px
}
.info tr td:nth-child(1) {  
  font-weight: bold;
}
.cursor{
  cursor: pointer;
}
.actived{
  background-color: #666;
  color: #fff
}
a{
  color: #666
}
.column6{
  text-align: left !important;
 /* white-space: nowrap; */
  /* width: 100%; */
  /* overflow: hidden;              "overflow" value must be different from "visible" */
  /* text-overflow:    ellipsis; */
}
@media only screen and (max-width: 600px) {
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  /* table-layout: fixed; */
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}
}
</style>