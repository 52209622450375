<template>
  <div>
    <div class="content-all" v-if="loading">
      <div class="spinner-border text-success" role="status" >
        <span class="visually-hidden">Loading...</span>
      </div> 
    </div>
    <nav class="navbar navbar-expand-md navbar-dark bg-dark">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">Londrisoft AgileMS</a>
        <div class="d-flex" action="#">
          <input class="form-control me-2" v-model="search" v-mask="'##.###.###/####-##'" type="search" placeholder="Pesquise por CNPJ" aria-label="Search">
          <button class="btn btn-outline-success" type="submit">Buscar</button>
        </div>
    </div>  
    </nav>

    <main class="container mt-5">     
      <table>
        <thead>
          <tr class="row100 head">
            <td class="cell100 column2">#</td>
            <td class="cell100 column3">CNPJ/CPF</td>
            <td class="cell100 column4">Email</td>
            <td class="cell100 column5">Nome</td>
            <td class="cell100 column6">Status</td>
            <td class="cell100 column7">Data</td> 
            <td class="cell100 column8"></td>

          </tr>
        </thead>
        <tbody>
          <tr class="row100 body"  v-for="(company, index) in pages[pageSelect]" :key="index" >
            <td class="cell100 column2">{{company.CompanyId}}</td>
            <td class="cell100 column3">{{company.CnpjCpf}}</td>
            <td class="cell100 column4">{{company.Email}}</td>
            <td class="cell100 column5">{{company.BusinessName}} {{company.FictitiousName}}</td>
            <td class="cell100 column6">
              <div class="text-warning"  v-if="company.Status ==1">
                <span class="badge bg-success">Ativo</span>
              </div>
              <div class="text-warning"  v-if="company.Status == 0">
                <span class="badge bg-secondary">Inativo</span>
              </div>
            </td>
            <td class="cell100 column7">{{company.DateCreated | moment('DD/MM/YYYY HH:mm:ss')}}</td>
            <td class="cell100 column8"> 
              <i @click="openModal(company)" class="fas fa-eye"></i>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="row">
        {{companies.length}}
      </div>

      <div class="row" v-if="!blockRefresh">
        <div class="col-12 text-center">
          <nav aria-label="Page navigation example">
            <ul class="pagination-custom">
              <li class="page-item-custom"><a v-show="pageSelect != 0" @click=" pageSelect--"><i class="fas fa-chevron-left"></i></a></li>
              <div class="page-item-custom">
                <span>{{pageSelect+1}}</span>/
                <span>{{pages.length}}</span>
              </div>
              <!-- <li class="page-item" v-for="(page,index) in pages" :key="index"><a class="page-link" href="#" @click="selectPage(index)" :class="{'actived' :index == pageSelect}" >{{index + 1}}</a></li> -->
              <li class="page-item-custom"><a v-show="pageSelect != pages.length - 1" @click="pageSelect++"><i class="fas fa-chevron-right"></i></a></li>
            </ul>
          </nav>
        </div>
      </div>


    </main>

    <b-modal id="modal-1" title="Detalhes" v-model="modal" hide-footer size="lg">
      <div class="row">
        <div class="col-8">
          <span>Razão social</span> <br>
          <span>{{company.FictitiousName}}</span><br><br>
          <span>E-mail</span> <br>
          <span>{{company.Email}}</span>
        </div>
        <div class="col-4">
          <span>Status</span> <br>
          <div class="text-warning"  v-if="company.Status ==1">
            <span class="badge bg-success">Ativo</span>
          </div>
          <div class="text-warning"  v-if="company.Status == 0">
            <span class="badge bg-secondary">Inativo</span>
          </div>
          <br><br>
          <span>CNPJ/CPF</span> <br>
          <span>{{company.CnpjCpf}}</span><br>
        </div>
      </div>
      <div class="row">
        <div class="col-12 p-3">
          <table class="table">
            <thead>
              <tr>
                <td>E-mail</td>
                <td>Tipo</td>
                <td>Sincronizado</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users" :key="user.UserId" >
                <td>{{user.email}}</td>
                <td>
                  <span class="badge bg-secondary" v-if="user.type != 1">Local</span>
                  <span class="badge bg-primary" v-if="user.type == 1">Cloud</span>
                </td>
                <td>
                  <!-- {{user.userActivityDirectory}} -->
                  <span class="badge bg-secondary" v-if="user.userActivityDirectory == null">Não</span>
                  <span class="badge bg-primary" v-if="user.userActivityDirectory != null">Sim</span>
                </td>
              </tr>
            </tbody>
          </table>

          </div>
          <div class="col-8 offset-2 text-center" v-if="validAD()">
              <button @click="syncAD(company.CnpjCpf)" class="btn btn-primary btn-block">Sincronizar</button>
          </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
// @ is an alias to /src
import '../assets/pagination.css'
import companies from '../views/companies.json'

export default {
  name: 'Home',
    data (){
    return {
      companies:[],
      pages:[[]],
      pageSelect:0,
      blockRefresh:false,
      search:"",
      loading:false,
      modal:false,
      company:{},
      users:[],
      filterCompany:companies,
      companyLocale:[]
    }
  },
  watch: {
    search: function (val){
      console.log(val)
      this.filter(val);
    }
  },
  created(){
    this.getCompany();
  },
  methods:{
    validAD(){
      let valid = false;
      this.users.forEach((item)=>{ 
        if(item.userActivityDirectory == null){
          console.log(true)
          valid = true;
        }
      })
      return valid;
    },
    getUsers(cnpj){
      this.loading = true;
      fetch('https://api.serverlondrisoft.com:8006/UserGestor/UsersByCompany/'+cnpj)
      .then(response => response.json())
      .then(data => {
        this.users = data;
        this.loading = false;
      });
    },
    openModal(company){
      this.modal = true;
      this.company = company;
      this.getUsers(this.company.CnpjCpf);
    },
    filter(cnpj){
      cnpj = cnpj.replace(/[^a-zA-Z0-9]/g,"");
      if(cnpj.length != 0){
          this.pages[0] = this.companies.filter((value) => { return value.CnpjCpf.indexOf(cnpj) != -1 });
          this.blockRefresh = true;
      }
      else{
        this.blockRefresh = false;
        this.getCompany();
      }
    },
    selectPage(index){
      this.pageSelect = index;
    },
    syncAD(cnpj){
      this.loading = true;
      fetch('http://192.168.4.62:6001/CompanyUser/syncActivityDirectory/'+cnpj)
      .then(response => {
        this.loading = false;
        this.getUsers(cnpj);
      });
    },
    getCompany(){
      this.loading = true;
      this.companies = [];
      this.pages = [[]];
      fetch('http://192.168.4.62:7000/Company')
      .then(response => response.json())
      .then(data => {
        // data = data.filter((value)=>{ 
        //   if(this.filterCompany.includes(value.CnpjCpf)){
        //     this.companyLocale.push(value.CnpjCpf);
        //     return value;
        //   }
        // });
        this.companies = data.reverse();
         let pageIndex = 0;
        for (let index = 0; index < this.companies.length; index++) {
           if(this.pages[pageIndex] == undefined) {
              this.pages[pageIndex] = [];
           } 

           if(this.pages[pageIndex].length == 9){
             pageIndex++;
           }else{
             if(index != 0 && this.companies[index].status != 99)
              this.pages[pageIndex].push(this.companies[index]);
           }
         }
        this.loading = false;
      });
    }
  }
}
</script>
<style scoped>
.content-all{
  width: 100vw !important;
  height: 100vh !important;
  position: fixed;
  z-index: 9000;
  background-color:rgba(255, 255, 255, 0.274);
  text-align: center;
}
.content-all .spinner-border{
  margin: 300px auto;
}
.fas{
  font-size: 25px;
  cursor: pointer;
}
.column4{
  width: 120px !important;
}
.column5{
  width: 700px !important;
}
.column7{
  width: 500px !important;
}
.navigation{
  width: 100px !important;
}
.page-item-custom{
  cursor: pointer;
  display: inline-block;
  margin: 30px;
}
tr{
  padding:20px
}
td{
  padding: 10px;
  margin: 10px !important;
}
.info tr td{
  padding: 10px;
  margin: 10px !important;
}
.info tr td:nth-child(1) {  
  font-weight: bold;
}
.cursor{
  cursor: pointer;
}
.actived{
  background-color: #666;
  color: #fff
}
a{
  color: #666
}
.column6{
  width: 75px !important;
  text-align: left !important;
 /* white-space: nowrap; */
  /* width: 100%; */
  /* overflow: hidden;              "overflow" value must be different from "visible" */
  /* text-overflow:    ellipsis; */
}
@media only screen and (max-width: 600px) {
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  /* table-layout: fixed; */
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .45em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}
}
</style>